import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Wrapper = styled.div`
  margin: 5.625rem auto 0;
  padding: 7.5rem 9.722vw 5.625rem;

  @media (max-width: 991px) {
    padding: 1.875rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  text-transform: uppercase;
  line-height: 3.125rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  overflow: hidden;
  margin-top: 1.875rem;

  a {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    text-decoration: underline;
  }
  p {
    margin-block-end: 1em;
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 1.25rem;
      position: relative;
      &:before {
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        background: ${({ theme }) => theme.colors.variants.primaryDark};
        border-radius: 50%;
        position: absolute;
        top: 0.9375rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 2rem;

    ul {
      li {
        &:before {
          top: 0.875rem;
        }
      }
    }
  }
`
